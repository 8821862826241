import React, { Fragment, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Modal from "react-bootstrap/Modal";
import { CLIENT_STOCK_RSURL, STOCK_ISSUE_URL, UNKNOWN_STOCK_RSURL, userHasAccess } from '../../../../api/userUrl';
import { userGetMethod, userDeleteMethod } from '../../../../api/userAction';
import { AddButton, EditButton, DeleteButton, PerPageBox, PanelRefreshIcons, ShowButton } from '../../../common/GlobalButton';
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import Show from './Show';

export default function ListData(props) {
    const [clientStockData, setClientStockData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [hasAccess, setHasAccess] = useState({});
    const [accLoad, setAccLoad] = useState(true);
    const [currentPage, setCurrentPage] = useState();
    const [perPage, setPerPage] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [ascDesc, setAscDesc] = useState(false);
    const [sortParam, setSortParam] = useState(1);
    const [show, setShow] = useState(false);
    const [showId, setShowId] = useState(0);

    var menuId = 0;
    if (props.location.state === undefined) {
        menuId = 0;
    } else {
        menuId = props.location.state.params.menuId;
    }
    
    useEffect(() => {
        // ADD,EDIT,DELETE,SHOW ACCESS CHECK
        if (typeof menuId === 'object' && menuId !== null && 'menuId' in menuId) {
                    userGetMethod(`${userHasAccess}/${menuId.menuId}`)
                        .then(response => {
                            setHasAccess(response.data);
                            setAccLoad(false);
                        });
                } else {
                    userGetMethod(`${userHasAccess}/${menuId}`)
                        .then(response => {
                            setHasAccess(response.data);
                            setAccLoad(false);
                        });
                }
        // userGetMethod(`${userHasAccess}/${menuId}`)
        //     .then(response => {
        //         setHasAccess(response.data);
        //         setAccLoad(false);
        //     });
        
        // TABLE DATA READY
        pageChange();
    },[]);

    useEffect(() => {
        perPageBoxChange();
    },[perPage])

    const handleSearchText = (e) => {
        setSearchText(e);
    }
    const searchHandler = (e) => {
        setIsLoading(true);
        userGetMethod(ascDesc === true ? `${STOCK_ISSUE_URL}?page=${1}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`: `${STOCK_ISSUE_URL}?page=${1}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`)
        .then(response => {
            setCurrentPage(response.data.stockIssueProducts.current_page)
            // setPerPage(response.data.stockIssueProducts.per_page)
            setTotalData(response.data.stockIssueProducts.total)
            setClientStockData(response.data.stockIssueProducts.data)
            setIsLoading(false);
        })
        .catch(error => console.log(error)); 
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          searchHandler();
        }
      };
    const deleteHandler = (itemId, deleteLink) => {
        userDeleteMethod(deleteLink, itemId)
            .then(response => {
                if (response.data.status == 1) {
                    setIsLoading(true);
                    let newData = clientStockData.filter(data => data.id != itemId);
                    setClientStockData(newData);
                    setIsLoading(false);
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(error => toast.error(error));
    }

    const pageChange = (pageNumber = 1) => {
        setIsLoading(true);
        // TABLE DATA READY
        // userGetMethod(`${CLIENT_STOCK_RSURL}?page=${pageNumber}&perPage=${perPage}&searchText=${searchText}`)
        userGetMethod(ascDesc === true? `${STOCK_ISSUE_URL}?page=${pageNumber}&perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`: `${STOCK_ISSUE_URL}?page=${pageNumber}&perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`)
            .then(response => {
                setCurrentPage(response.data.stockIssueProducts.current_page)
                // setPerPage(response.data.stockIssueProducts.per_page)
                setTotalData(response.data.stockIssueProducts.total)
                setClientStockData(response.data.stockIssueProducts.data)
                setIsLoading(false);
            })
            .catch(error => console.log(error))
    }

    const perPageBoxChange = (e) => {
        setIsLoading(true);
        // TABLE DATA READY
        // userGetMethod(`${CLIENT_STOCK_RSURL}?perPage=${perPage}&searchText=${searchText}`)

        userGetMethod(ascDesc === true ? `${STOCK_ISSUE_URL}?perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`: `${STOCK_ISSUE_URL}?perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`)
            .then(response => {
                setCurrentPage(response.data.stockIssueProducts.current_page)
                // setPerPage(response.data.stockIssueProducts.per_page)
                setTotalData(response.data.stockIssueProducts.total)
                setClientStockData(response.data.stockIssueProducts.data)
                setIsLoading(false)
            })
            .catch(error => console.log(error))
    }

    useEffect(()=>{
        let ascUrl = '';
        if (ascDesc === true) {
            ascUrl = `${STOCK_ISSUE_URL}?perPage=${perPage}&searchText=${searchText}&asc=${sortParam}&desc=`;
        } else {
            ascUrl = `${STOCK_ISSUE_URL}?perPage=${perPage}&searchText=${searchText}&asc=&desc=${sortParam}`;
        }
        
        setIsLoading(true);
        // TABLE DATA READY
        userGetMethod(ascUrl)
            .then(response => {
                setCurrentPage(response.data.stockIssueProducts.current_page)
                // setPerPage(response.data.pendingDesignToFactories.per_page)
                setTotalData(response.data.stockIssueProducts.total)
                setClientStockData(response.data.stockIssueProducts.data)
                setIsLoading(false)
            })
            .catch(error => console.log(error))
    },[ascDesc])

    const handleShow = (id) => {
        setShow(true);
        setShowId(id);
      };
    const handleClose = () => setShow(false);

    const sortHandler = (params) => {
        setAscDesc(prevAscDesc => !prevAscDesc);
        setSortParam(params)
        // let ascUrl = '';
        // if (ascDesc === true) {
        //     ascUrl = `${CLIENT_STOCK_RSURL}?asc=${params}&desc=`;
        // } else {
        //     ascUrl = `${CLIENT_STOCK_RSURL}?asc=&desc=${params}`;
        // }
        
        // setIsLoading(true);
        // // TABLE DATA READY
        // userGetMethod(ascUrl)
        //     .then(response => {
        //         setCurrentPage(response.data.clientStocks.current_page)
        //         // setPerPage(response.data.clientStocks.per_page)
        //         setTotalData(response.data.clientStocks.total)
        //         setClientStockData(response.data.clientStocks.data)
        //         setIsLoading(false)
        //     })
        //     .catch(error => console.log(error))
    }

    return (
        
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Stock Issue List</h5>
                                    </div>
                                    <div className="col-md-6">
                                        {/* <PanelRefreshIcons panelRefresh={pageChange} /> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-5 col-lg-5">
                                    <div className="input-group text-box searchBox">
                                        <input
                                            type="text"
                                            className="form-control input-txt-bx"
                                            placeholder="Type to Search..."
                                            onChange={(e) => handleSearchText(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <div className="input-group-append">
                                            <button 
                                                className="btn btn-primary btn-sm" 
                                                type="button" 
                                                onClick={searchHandler} 
                                            >Go
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    {/* <div className="custom-table-pagination m-r-10">
                                        <label className="mt-3">
                                            <span>
                                                <select className="form-control pagi-select" name="design_to_design_status" onChange={perPageBoxChange} >
                                                    <option value="2">All</option>
                                                    <option value="0" selected={true} >Pending</option>
                                                    <option value="1">Done</option>
                                                </select>
                                            </span>
                                        </label>
                                    </div> */}
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <AddButton link="consumableStockIssue/add" menuId={menuId} />
                                    <PerPageBox pageBoxChange={perPageBoxChange} perPage={perPage} setPerPage={setPerPage}/>
                                </div>
                            </div>
                                
                            <div className="card-body datatable-react">
                                {isLoading ? (<img src={process.env.PUBLIC_URL+'/preloader.gif'} alt="Data Loading"/>):
                                (
                                    <div className="table-responsive">
                                        <table className="table table-border-horizontal">
                                            <thead>
                                                <tr>
                                                    <th scope="col" width="5%" >SL.</th>
                                                    {/* <th scope="col" width="5%" onClick={() => sortHandler(1)}><i className="fa fa-sort"></i> SL.</th> */}
                                                    <th style={{cursor:'pointer'}} scope="col" width="20%" onClick={() => sortHandler(1)}><i className="fa fa-sort"></i> Issue No</th>
                                                    <th style={{cursor:'pointer'}} scope="col" width="20%" onClick={() => sortHandler(1)}><i className="fa fa-sort"></i> Issue Date</th>
                                                    <th style={{cursor:'pointer'}} scope="col" width="20%" onClick={() => sortHandler(1)}><i className="fa fa-sort"></i> PNO From</th>
                                                    <th style={{cursor:'pointer'}} scope="col" width="20%" onClick={() => sortHandler(1)}><i className="fa fa-sort"></i> PNO To</th>
                                                    <th  scope="col" width="10%"> Action</th>
                                                    {/* <th scope="col" width="7%">Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { 
                                                    clientStockData.length > 0 ? 
                                                        <>
                                                            {clientStockData.map((item, index) =>           
                                                                (
                                                                    <tr key={index}>
                                                                        <td scope="row">{ ((index+1) + (currentPage == 1 ? 0 : (currentPage*perPage - perPage))) }</td>
                                                                        <td>{item.issue_no}</td>
                                                                        <td>{item.issue_date}</td>
                                                                        <td>[{item.pno_from[0].id}]-{item.pno_from[0].consumeable_user}</td>
                                                                        <td>[{item.pno_to[0].id}]-{item.pno_to[0].consumeable_user}</td>
                                                                        <td>{clientStockData.length > 0 ?(
                                                                            <ShowButton 
                                                                            handleShow={() => handleShow(item.id)}
                                                                                menuId={menuId} /> ):('')}
                                                                        </td>
                                                                    </tr>
                                                                )                
                                                            )}
                                                        </> 
                                                    : <tr><td colSpan="12" className="text-center">No data found</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <Pagination 
                                    activePage={currentPage}
                                    itemsCountPerPage={perPage}
                                    totalItemsCount={totalData}
                                    onChange={pageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    firstPageText="First"
                                    lastPageText="Last"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
            <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton style={{ maxWidth: "none", width: "100%" }}>
                <Modal.Title>Stock Issue History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Show showId={showId}></Show>
            </Modal.Body>
            </Modal>
        </div>
        </Fragment>
    )
}